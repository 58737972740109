'use client'

import React, { Component, useEffect, useState } from 'react'
import Image from 'next/image'
import type { Metadata } from 'next'

import { Button } from '@components/design'

import text from '/public/Group.png'
import bg from '/public/not-found-bg.jpg'

export const metadata: Metadata = {
  title: 'Page Not Found',
}

export default function NotFoundPage() {
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setUrl(window.location.pathname)
    }
  }, [])

  return (
    <div
      className="w-screen h-screen bg-center bg-cover not-found"
      style={{
        backgroundImage: `url(${bg.src})`,
      }}
    >
      <div className="flex items-start h-full lg:items-center md:justify-center lg:justify-start ">
        <div className="relative pt-16 pl-5 lg:pt-0 lg:pl-32">
          <div className="absolute -top-8 lg:-top-20 -right-16 lg:left-72 w-52">
            <Image src={text} alt="" />
          </div>
          <div className="mb-4 font-extrabold text-white uppercase font-akzidenz-grotesk-pro text-h1-m">
            OOPS
          </div>
          <div className="mb-2 text-sm font-extrabold text-white uppercase font-akzidenz-grotesk-pro">
            404 - nothing here
          </div>
          <div className="mb-3 text-sm font-extrabold text-white font-akzidenz-grotesk-pro w-72">
            There is no page at the following URL {url}
          </div>
          <a href="/">
            <Button className="my-3">Return to home page</Button>
          </a>
        </div>
      </div>
    </div>
  )
}
